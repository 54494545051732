<template>
  <div
    class="flex flex-row items-stretch w-full rounded-alt-lg px-sm md:px-md mt-md gap-x-sm"
    :class="[classes, typeConfig.backgroundClass]"
  >
    <div
      v-if="!noIcon"
      class="relative min-h-[41px] shrink-0 w-[36px]"
      :class="typeConfig.iconBackgroundClass"
    >
      <FaIcon
        :icon-class="iconClass ?? typeConfig.defaultIconClass"
        class="absolute w-full m-auto text-xl -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
        :class="typeConfig.iconColor"
      ></FaIcon>
    </div>
    <slot v-if="$slots.default" />
    <span v-else class="self-center py-sm">{{ t(description) }}</span>
  </div>
</template>
<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import { NotificationType } from './types';

const props = defineProps({
  type: {
    type: String as PropType<NotificationType>,
    required: true,
  },
  iconClass: {
    type: String,
    required: false,
    default: undefined,
  },
  description: {
    type: String,
    required: false,
    default: '',
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
  noIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const { t } = useTrans();

const typeConfig = computed(() => {
  switch (props.type) {
    case NotificationType.WARN:
      return {
        iconBackgroundClass: 'bg-status-warn-light',
        iconColor: 'text-text-base',
        backgroundClass: 'bg-status-warn-lighter',
        defaultIconClass: 'far fa-exclamation-circle',
      };
    case NotificationType.SUCCESS:
      return {
        iconBackgroundClass: 'bg-status-success-light',
        iconColor: 'text-text-base',
        backgroundClass: 'bg-status-success-lighter',
        defaultIconClass: 'far fa-exclamation-circle', //change if needed
      };
    case NotificationType.ERROR:
      return {
        iconBackgroundClass: 'bg-status-danger-light',
        iconColor: 'text-white',
        backgroundClass: 'bg-status-danger-lighter',
        defaultIconClass: 'fal fa-exclamation-circle', //change if needed
      };
    case NotificationType.INFO:
      return {
        iconBackgroundClass: 'bg-primary-light',
        iconColor: 'text-white',
        backgroundClass: 'bg-primary-lighter',
        defaultIconClass: 'far fa-exclamation-circle', //change if needed
      };
    default:
    case NotificationType.INFO_GREY:
      return {
        iconBackgroundClass: 'bg-border-light',
        iconColor: 'text-text-base',
        backgroundClass: 'bg-background-base-opc50',
        defaultIconClass: 'fal fa-exclamation-circle', //change if needed
      };
  }
});
</script>
